import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/styles/global.css'
import './assets/styles/nav.css'
import './assets/styles/home.css'
import './assets/styles/product-details.css'
import './assets/styles/products.css'
import './assets/styles/contact.css'
import './assets/styles/transitions.css'
import './assets/styles/about.css'
import './assets/styles/notFound.css'

createApp(App)
    .use(store)
    .use(router)
    .mount('#app')
