
<template>
    <div class="not-found-container">
      <h1>{{ error }}</h1>
      <h4 class="not-found-text">{{ warning }}</h4>
      <router-link to="/" class="not-found-button">
        <h2>{{ solution }}</h2>
      </router-link>
    </div>
</template>
  
<script>
export default {
  computed: {
    error() {
      return this.$store.state.selectedLanguage === 'en' ? 'Sorry, Page Not Found!': 'Przepraszamy, Nie znaleziono Strony!';
    },
    warning() {
      return this.$store.state.selectedLanguage === 'en' ? "Sorry, we couldn't find the page you're looking for. Perhaps you've mistyped the URL? Be sure to check your spelling." : "Przepraszamy, nie znaleźliśmy strony, której szukasz. Być może błędnie wpisałeś adres URL? Pamiętaj, aby sprawdzić pisownię.";
    },
    solution() {
      return this.$store.state.selectedLanguage === 'en' ? 'Home Page': 'Strony Głównej';
    }
  } 
}
</script>