<template>
  <div class="nav" v-if="!menuModal">
    <div class="nav-img-container">
      <img src="@/assets/images/logo.png" class="logo"/>
      <h5 class="nav-slogan">{{ slogan[0] }}</h5>
      <h5 class="nav-slogan">{{ slogan[1] }}</h5>
    </div>
    <div class="nav-routes-container-pc">
      <router-link to="/" exact> 
        <h2>{{ home }}</h2>
      </router-link> 
      <router-link to="/products" :class="{ 'router-link-active': isProductsActive }">
        <h2>{{ products }}</h2>
      </router-link>
      <router-link to="/about"> 
        <h2>{{ about }}</h2>
      </router-link>
      <router-link to="/contact">
        <h2>{{ contact }}</h2>
      </router-link>
    </div>
    <select v-model="currentLanguage" @change="changeLanguage" :class="styleForLang">
      <option value="pl">PL</option>
      <option value="en">EN</option>
    </select>
    <img src="@/assets/images/menu.png" class="nav-mobile" @click="toggleMenuModal"/>
  </div>

  <div v-if="menuModal" class="nav-modal">
    <div class="nav-background-modal" @click="closeMenuModal"></div>
    <div class="nav-routes-container-mobile">
      <transition name="slideFromTop" appear style="transition-delay: 0.4s">
        <router-link to="/" exact @click="closeMenuModal"> 
          <h2>{{ home }}</h2>
        </router-link>
      </transition> 

      <transition name="slideFromTop" appear style="transition-delay: 0.3s">
        <router-link to="/products" :class="{ 'router-link-active': isProductsActive }" @click="closeMenuModal">
          <h2>{{ products }}</h2>
        </router-link>
      </transition>

      <transition name="slideFromTop" appear style="transition-delay: 0.2s">
        <router-link to="/about" @click="closeMenuModal"> 
          <h2>{{ about }}</h2>
        </router-link>
      </transition>
      
      <transition name="slideFromTop" appear style="transition-delay: 0.1s">
        <router-link to="/contact" @click="closeMenuModal">
          <h2>{{ contact }}</h2>
        </router-link>
      </transition>
      
      <transition name="slideFromTop" appear>
        <select v-model="currentLanguage" @change="changeLanguageMobile" class="nav-select-mobile">
          <option value="pl">PL</option>
          <option value="en">EN</option>
        </select>
      </transition>
    </div>
  </div>

  <router-view v-slot="{ Component }" v-if="!menuModal">
    <component :is="Component"></component>
  </router-view>
</template>

<script>
export default {
  data() {
    return {
      currentLanguage: this.$store.state.selectedLanguage,
      menuModal: false
    }
  },
  computed: {
    isProductsActive() {
      return this.$route.path.includes('/products');
    },
    slogan() {
      return this.$store.state.selectedLanguage === 'en' ? ['Manufacturer of ropes,', 'cords and expanders'] : ['Producent lin, sznurków', 'i ekspandorów'];
    },
    home() {
      return this.$store.state.selectedLanguage === 'en' ? 'Home' : 'Strona główna';
    },
    products() {
      return this.$store.state.selectedLanguage === 'en' ? 'Products' : 'Produkty';
    },
    about() {
      return this.$store.state.selectedLanguage === 'en' ? 'About' : 'O firmie';
    },
    contact() {
      return this.$store.state.selectedLanguage === 'en' ? 'Contact' : 'Kontakt';
    },
    styleForLang() {
      return this.$store.state.selectedLanguage === 'en' ? 'nav-select-en' : 'nav-select-pl';
    }
  },
  methods: {
    changeLanguage() {
      this.$store.dispatch('updateLanguage', this.currentLanguage);
    },
    changeLanguageMobile() {
      this.$store.dispatch('updateLanguage', this.currentLanguage);
      this.menuModal = false;
    },
    toggleMenuModal() {
      this.menuModal = true;
    },
    closeMenuModal() {
      this.menuModal = false;
    }
  }
}
</script>
