<template>
    <h1> {{ text }} </h1>
</template>

<script>
    export default {
        computed: {
            text() {
                if(this.$store.state.selectedLanguage === 'en') {
                    return 'Special string - heat-resistant'
                }
                else {
                    return 'Sznurek specjalny - termoodporny'
                }
            }
        }
    }
</script>