<template>
   <li v-for='(item, index) in description' :key='index'>{{ item }}</li>   
</template>

<script>
    export default {
        computed: {
            description() {
                if(this.$store.state.selectedLanguage === 'en') {
                    return ['braid - made of the highest quality cotton', 
                            'diameter - from 3mm to 6mm',
                            'environmentally friendly and ecological, made from natural fabric',
                            'packing method: in a carton, on a spool',
                            'directly from the Polish manufacturer']
                }
                else {
                    return ['oplot - najwyższej jakości bawełna', 
                            'średnica - od 3mm do 6mm',
                            'przyjazne środowisku i ekologiczne, wykonane z naturalnego surowca',
                            'sposób konfekcji: w kartonach, na szpulach',
                            'bezpośrednio od polskiego producenta']
                }
            }
        }
    }
</script>