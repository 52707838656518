import { createStore } from 'vuex';

export default createStore({
  state: {
    selectedLanguage: localStorage.getItem('selectedLanguage') || 'pl'
  },
  mutations: {
    mutation: (state, val) => (state.selectedLanguage = val),
  },
  actions: {
    updateLanguage({commit}, val) {
      commit('mutation', val);
      localStorage.setItem('selectedLanguage', val);
    }
  }
});