<template>
    <p> {{ text }} </p>
    <li v-for='(item, index) in usage' :key='index'>{{ item }}</li>
</template>

<script>
    export default {
        computed: {
            text() {
                if(this.$store.state.selectedLanguage === 'en') {
                    return 'In the industry: tourism, construction, furniture, motorization, agriculture, tourism, advertising, and in particular for:'
                }
                else {
                    return 'W przemyśle: turystycznym, budowlanym, meblarskim, motoryzacyjnym, rolnictwie, turystyce, reklamie a w szczególności do:'
                }
            },
            usage() {
                if(this.$store.state.selectedLanguage === 'en') {
                    return ['guy lines for tents, advertising balloons, banners',
                            'garden equipment',
                            'floating equipment', 
                            'hammocks, sleds, swings'] 
                }
                else {
                    return ['odciągów do namiotów, balonów reklamowych, banerów',
                            'urządzeń ogrodowych',
                            'sprzętu pływającego',
                            'hamaków, sanek, huśtawek']
                }
            }
        }
    }
</script>