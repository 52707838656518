<template>
    <h3> {{ text }} </h3>
</template>

<script>
    export default {
        computed: {
            text() {
                if(this.$store.state.selectedLanguage === 'en') {
                    return 'It is possible to send you a sample of the products we offer.'
                }
                else {
                    return 'Istnieje możliwość wysłania do Państwa wzornika oferowanych przez nas produktów.'
                }
            }
        }
    }
</script>