<template>
  <div class="home-container">
    <div class="home-first-page-container">
      <transition name="appear" appear style="transition-delay: 0.1s">
        <div class="home-first-left-container">
          <img class="home-img" src="@/assets/images/swiper/strings/braided_2.webp"/>
          <h2>{{ history }}</h2>
        </div>
      </transition>
      <transition name="appear" appear>
        <div class="home-first-right-container">
          <div class="home-logo-container">
            <div class="home-logo-inside">
              <img class="home-logo-img" src="@/assets/images/logo.png" />
            </div>
            <div class="home-slogan-inside">
              <h1>{{ tradition_first_line }}</h1>
              <h1>{{ tradition_second_line }}</h1>
            </div>
          </div>
          <h2>{{ slogan }}</h2>
          <img class="home-first-img" src="@/assets/images/swiper/ropes/braided_7.webp" />
        </div>
      </transition>
    </div>
    <div class="home-second-page-container">
      <transition name="appear" appear style="transition-delay: 0.3s">
        <div class="home-second-left-container">
          <img class="home-second-img" src="@/assets/images/swiper/strings/braided_7.webp"/>
          <h2>{{ offer }}</h2>
        </div>
      </transition>
      <transition name="appear" appear style="transition-delay: 0.2s">
        <div class="home-second-right-container">
          <h2>{{ specialization }}</h2>
          <img class="home-second-img" src="@/assets/images/swiper/expanders/pcv_1.webp"/>
        </div>
      </transition>
    </div>
    <transition name="appear" appear style="transition-delay: 0.4s">
      <div class="home-third-page-container">
        <h2>{{ orders }}</h2>
        <div class="home-third-images-container">
          <img class="home-third-image" src="@/assets/images/swiper/ropes/braided_1.webp"/>
          <img class="home-third-image" src="@/assets/images/swiper/ropes/braided_4.webp"/>
        </div>
        <h2>{{ invitation }}</h2>
        <router-link to="/products">
          <img v-if="this.$store.state.selectedLanguage === 'en'" class="home-link-image" src="@/assets/images/products_link_en.jpg"/>
          <img v-else class="home-link-image" src="@/assets/images/products_link_pl.jpg"/>
        </router-link>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  computed: {
    slogan() {
      return this.$store.state.selectedLanguage === 'en' ? 'Polish manufacturer of ropes, strings, cords, and expanders of wide use.': 'Polski producent lin, sznurków i ekspandorów o szerokim zastosowaniu.';
    },
    tradition_first_line() {
      return this.$store.state.selectedLanguage === 'en' ? 'A COMPANY WITH TRADITIONS' : 'FIRMA Z TRADYCJAMI';
    },
    tradition_second_line() {
      return this.$store.state.selectedLanguage === 'en' ? '30 YEARS OF EXPERIENCE' : '30 LAT DOŚWIADCZENIA';
    },
    history() {
      return this.$store.state.selectedLanguage === 'en' ? 'We have been on the market continuously since 1989, and our offer is constantly expanding.' : 'Na rynku istniejemy nieprzerwanie od 1989 roku, a nasza oferta nieustannie się powiększa.';
    },
    specialization() {
      return this.$store.state.selectedLanguage === 'en' ? 'We specialize in the production of ropes, strings, and cords made from polypropylene, polyester, polyamide, cotton, and expanders (elastic cord) with a diameter of 1mm to 10mm, in various colours.' : 'Specjalizujemy się w produkcji lin i sznurków polipropylenowych, poliestrowych, poliamidowych, bawełnianych oraz ekspandorów (liny gumowej) o średnicy od 1mm do 10mm, w różnych wersjach kolorystycznych.';
    },
    offer(){
      return this.$store.state.selectedLanguage === 'en' ? 'We offer high-quality products, both one-coloured and made in several colours. Thanks to the wide range of our machine park, we can produce braided and knitted ropes, cords, and expanders.':'Oferujemy wysokiej jakości produkty, zarówno jednobarwne jak i wykonane w kilku kolorach. Dzięki szerokiej ofercie naszego parku maszynowego możemy produkować liny, sznurki i ekspandory plecione oraz dziane.';
    },
    orders() {
      return this.$store.state.selectedLanguage === 'en' ? 'We carry out orders focused on the individual needs of our clients and provide profitable solutions.' : 'Realizujemy zamówienia dostosowane do indywidualnych potrzeb klientów, zapewniając korzystne rozwiązania.';
    },
    invitation() {
      return this.$store.state.selectedLanguage === 'en' ? 'We invite you to familiarize yourself with our offer.' : 'Serdecznie zapraszamy do zapoznania się z naszą ofertą.';
    }
  }
}
</script> 
