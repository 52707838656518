<template>
    <li v-for='(item, index) in usage' :key='index'>{{ item }}</li>
</template>

<script>
    export default {
        computed: {
            usage() {
                if(this.$store.state.selectedLanguage === 'en') {
                    return ['fixing and tensioning car covers and luggage trailers',
                            'fixing and stretching advertising banners',
                            'installation of tent halls',
                            'production of e.g. trampolines, sports equipment, covers for motorboats and sailboats',
                            'luggage and cargo securing'] 
                }
                else {
                    return ['mocowanie i naciąg plandek samochodowych oraz przyczep bagażowych',
                            'mocowanie i naciąg banerów reklamowych',
                            'montaż hal namiotowych',
                            'produkcja np. trampolin, przyrządów sportowych, pokrowców na motorówki oraz żaglówki',
                            'mocowanie bagażu i ładunków']
                }
            }
        }
    }
</script>