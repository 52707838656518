import { createRouter, createWebHistory } from 'vue-router'

import Home from '../views/Home.vue'
import Products from '../views/Products.vue'
import About from '../views/About.vue'
import Contact from '../views/Contact.vue'

//categories:
import Expanders from '../views/products/categories/Expanders.vue'
import Ropes from '../views/products/categories/Ropes.vue'
import Strings from '../views/products/categories/Strings.vue'

//expanders:
import ExpandersPcv from '../views/products/expanders/Pcv.vue'
import ExpandersPolypropylene from '../views/products/expanders/Polypropylene.vue'
import ExpandersPolyester from '../views/products/expanders/Polyester.vue'

//ropes:
import RopesPolyester from '../views/products/ropes/Polyester.vue'
import RopesBraided from '../views/products/ropes/Braided.vue'
import RopesKnitted from '../views/products/ropes/Knitted.vue'
import RopesHeatResistant from '../views/products/ropes/Heat-resistant.vue'

//strings:
import StringsBistor from '../views/products/strings/Bistor.vue'
import StringsBraided from '../views/products/strings/Braided.vue'
import StringsCotton from '../views/products/strings/Cotton.vue'
import StringsHeatResistant from '../views/products/strings/Heat-resistant.vue'
import StringsKnitted from '../views/products/strings/Knitted.vue'

//not found:
import PageNotFound from '../views/NotFound.vue'

//routes:
const routes = [

  //home:
  {
    path: '/',
    name: 'Home',
    component: Home
  }, 

  //products: 
  {
    path: '/products',
    name: 'Products',
    component: Products,
  },

  //about:
  {
    path: '/about',
    name: 'About',
    component: About
  },

  //contact:
  {
    path: '/contact',
    name: 'Contact',
    component: Contact
  },

  //product categories:
  {
    path: '/products/expanders',
    name: 'Expanders',
    component: Expanders
  },
  {
    path: '/products/ropes',
    name: 'Ropes',
    component: Ropes
  },
  {
    path: '/products/strings',
    name: 'Strings',
    component: Strings
  },

  //expanders:
  {
    path: '/products/expanders/pcv',
    name: 'ExpandersPcv',
    component: ExpandersPcv
  },
  {
    path: '/products/expanders/polypropylene',
    name: 'ExpandersPolypropylene',
    component: ExpandersPolypropylene
  },
  {
    path: '/products/expanders/polyester',
    name: 'ExpandersPolyester',
    component: ExpandersPolyester
  },

  //ropes:
  {
    path: '/products/ropes/polyester',
    name: 'RopesPolyester',
    component: RopesPolyester
  },
  {
    path: '/products/ropes/braided',
    name: 'RopesBraided',
    component: RopesBraided
  },
  {
    path: '/products/ropes/knitted',
    name: 'RopesKnitted',
    component: RopesKnitted
  },
  {
    path: '/products/ropes/heat-resistant',
    name: 'RopesHeatResistant',
    component: RopesHeatResistant
  },

  //strings:
  {
    path: '/products/strings/bistor',
    name: 'StringsBistor',
    component: StringsBistor
  },
  {
    path: '/products/strings/braided',
    name: 'StringsBraided',
    component: StringsBraided
  },
  {
    path: '/products/strings/cotton',
    name: 'StringsCotton',
    component: StringsCotton
  },
  {
    path: '/products/strings/heat-resistant',
    name: 'StringsHeatResistant',
    component: StringsHeatResistant
  },
  {
    path: '/products/strings/knitted',
    name: 'StringsKnitted',
    component: StringsKnitted
  },

  //not found:
  {
    path: '/:catchAll(.*)',
    name: 'PageNotFound',
    component: PageNotFound
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
