<template>
  <div class="contact"> 
    <transition name="appear" appear>
      <div class="contact-options-container">
        <h1>{{ contact }}</h1>
        <div class="contact-modal-transparent-pc">
          <div class="contact-internal-container">
            <div class="contact-internal-img-container">
              <img class="contact-internal-img" src="@/assets/images/location.png"/>
            </div>
            <div>
              <h3 class="contact-internal-text">PPHU LUMI Jacek Mierzejewski</h3>
              <h3 class="contact-internal-text">{{ street }}</h3>
              <h3 class="contact-internal-text">{{ city }}</h3>
              <h3 class="contact-internal-text">{{ nip }}</h3>
            </div>
          </div>
      
          <div class="contact-internal-container">
            <div class="contact-internal-img-container">
              <img class="contact-internal-img" src="@/assets/images/phone.png"/>
            </div>
            <h3>
              <a href="tel:+48501044980">{{ phone[0] }}</a>
            </h3>
          </div>
          <div class="contact-internal-container">
            <div class="contact-internal-img-container">
              <img class="contact-internal-img" src="@/assets/images/phone.png"/>
            </div>
            <h3>
              <a href="tel:+48572017741">{{ phone[1] }}</a>
            </h3>
          </div>
          <div class="contact-internal-container">
            <div class="contact-internal-img-container">
              <img class="contact-internal-img" src="@/assets/images/email.png"/>
            </div>
            <h3>
              <a href="mailto:lumi@lumi.com.pl">{{ emailAdress }}</a>
            </h3>
          </div>
        </div> 
      </div>
    </transition>

    <transition name="appear" appear style="transition-delay: 0.1s">
        <div class="contact-message-container">
          <h1>{{ message }}</h1>
          <div class="contact-mail">
            <input 
              class="contact-modal"
              type="text" 
              v-model="name" 
              :placeholder="namePlaceholder"
            />
            <input 
              class="contact-modal"
              type="text" 
              v-model="subject" 
              :placeholder="subjectPlaceholder"
            />
            <textarea class="contact-modal" v-model="mailMessage" :placeholder="messagePlaceholder" rows="10"></textarea>
            <button @click="composeEmail">
              <h4>{{ button }}</h4>
            </button>
            <div class="contact-checkbox-container">
              <input class="contact-checkbox" type="checkbox" v-model="isChecked"/>
              <h6 class="contact-checkbox-text">{{ agree }}</h6>
            </div>
          </div>
        </div>
    </transition>
     
    <transition name="appear" appear style="transition-delay: 0.2s">
      <div class="contact-map-container">
        <h1>{{ map }}</h1>
        <div style="border: 1px solid #eee;">
          <iframe class="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2472.59052509982!2d19.454343976997457!3d51.70393799632619!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x471a346c24d78e19%3A0x6d4cc3a586e2ceb9!2sLUMI%20Producent%20lin%2C%20sznur%C3%B3w%20i%20ekspandor%C3%B3w!5e0!3m2!1sen!2spl!4v1700409622988!5m2!1sen!2spl" height="440px" style="border:0 " allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>

export default {
  data () {
    return {
      name: "",
      subject: "",
      mailMessage: "",
      emailAdress: 'lumi@lumi.com.pl',
      isChecked: false
    }
  },
  computed: {
    nip() {
      return this.$store.state.selectedLanguage === 'en' ? 'NIP PL7271233346' : 'NIP 7271233346';
    },
    street() {
      return this.$store.state.selectedLanguage === 'en' ? 'st. Przestrzenna 67' : 'ul. Przestrzenna 67';
    },
    city() {
      return this.$store.state.selectedLanguage === 'en' ? '93-424 Łódź Poland' : '93-424 Łódź';
    },
    namePlaceholder() {
      return this.$store.state.selectedLanguage === 'en' ? 'Company / Name' : 'Firma / Imię i nazwisko';
    },
    subjectPlaceholder() {
      return this.$store.state.selectedLanguage === 'en' ? 'Subject' : 'Temat';
    },
    messagePlaceholder() {
      return this.$store.state.selectedLanguage === 'en' ? 'Enter your message here' : 'Wprowadź swoją wiadomość tutaj';
    },
    button() {
      return this.$store.state.selectedLanguage === 'en' ? 'Send' : 'Wyślij';
    },
    contact() {
      return this.$store.state.selectedLanguage === 'en' ? 'Contact' : 'Kontakt';
    },
    message() {
      return this.$store.state.selectedLanguage === 'en' ? 'Write a message' : 'Napisz do nas'; 
    },
    phone() {
      return this.$store.state.selectedLanguage === 'en' ? ['+48 501 044 980', '+48 572 017 741 ']: ['501 044 980', '572 017 741 '];
    },
    map() {
      return this.$store.state.selectedLanguage === 'en' ? 'Where to find us' : 'Znajdź nas';
    },
    getBody() {
      return this.$store.state.selectedLanguage === 'en' ? `Hello,\n${this.mailMessage}\nYours sincerely,\n${this.name}` : `Dzień dobry,\n${this.mailMessage}\nPozdrawiam,\n${this.name}`;
    },
    agree() {
      return this.$store.state.selectedLanguage === 'en' ? 'I agree to process my personal data by PPHU Lumi Jacek Mierzejewski, located in Poland, Łódź st. Przestrzenna 67, in order to respond to my message, by phone or e-mail.' : 'Zgadzam się na przetwarzanie moich danych osobowych przez PPHU Lumi Jacek Mierzejewski, z siedzibą w Łodzi ul. Przestrzenna 67, w celu odpowiedzi na moją wiadomość, drogą telefoniczną lub poprzez e-mail.';
    }
  },
  methods: {
    composeEmail() {
      if(this.isChecked) {
        const emailAddress = 'lumi@lumi.com.pl';
        const body = this.getBody;

        const mailtoLink = `mailto:${emailAddress}?subject=${encodeURIComponent(this.subject + " ")}&body=${encodeURIComponent(body)}`;
      
        window.location.href = mailtoLink;
      }
    }
  }
}
</script>
