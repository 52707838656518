<template>
    <h1> {{ text }} </h1>
</template>

<script>
    export default {
        computed: {
            text() {
                if(this.$store.state.selectedLanguage === 'en') {
                    return 'Elastic cord - polyester braided expander'
                }
                else {
                    return 'Liny gumowe - ekspandor oplot poliestrowy'
                }
            }
        }
    }
</script>