<template>
    <div v-if="modal" class="product-fullscreen-modal">
        <div class="product-background-modal" @click="closeModal"></div>
        <transition name="appear" appear>
            <swiper :slidesPerView="1" :spaceBetween="15" :modules="modalModules" :navigation="true" :pagination="{ clickable: true }" :controller="{ control: mainSwiper }" :zoom="true" class="product-modal-swiper" @swiper="setModalSwiper">
                <transition name="delayedAppear" appear style="transition-delay: 0.5s;">
                    <img src="@/assets/images/close.png" class="product-close-button mobile" @click="closeModal"/>
                </transition>
                <swiper-slide>
                    <div class="swiper-zoom-container">
                        <div class="swiper-lazy-preloader swiper-lazy-preloader-white mobile"></div>
                        <img src="@/assets/images/fullscreen/expanders/polypropylene_1.webp" class="product-image" loading="lazy"/>
                    </div>
                </swiper-slide>
                <swiper-slide>
                    <div class="swiper-zoom-container">
                        <div class="swiper-lazy-preloader swiper-lazy-preloader-white mobile"></div>
                        <img src="@/assets/images/fullscreen/expanders/polypropylene_2.webp" class="product-image" loading="lazy"/>
                    </div>
                </swiper-slide>
                <swiper-slide>
                    <div class="swiper-zoom-container">
                        <div class="swiper-lazy-preloader swiper-lazy-preloader-white mobile"></div>
                        <img src="@/assets/images/fullscreen/expanders/polypropylene_3.webp" class="product-image" loading="lazy"/>
                    </div>
                </swiper-slide>
                <swiper-slide>
                    <div class="swiper-zoom-container">
                        <div class="swiper-lazy-preloader swiper-lazy-preloader-white mobile"></div>
                        <img src="@/assets/images/fullscreen/expanders/polypropylene_4.webp" class="product-image" loading="lazy"/>
                    </div>
                </swiper-slide>            
            </swiper>
        </transition>
        
    </div>
    <div class="product-details">
        <Name class="product-modal mobile"/>
        <div class="product-carousel">
            <div class="product-swiper">
                <swiper :slidesPerView="1" :spaceBetween="15" :modules="mainModules" :navigation="true" :pagination="{ clickable: true }" :controller="{ control: modalSwiper }" :thumbs="{ swiper: thumbsSwiper}" @swiper="setMainSwiper">
                    <swiper-slide>
                        <img src="@/assets/images/swiper/expanders/polypropylene_1.webp" class="product-image" loading="lazy" @click="toggleModal"/>
                    </swiper-slide>
                    <swiper-slide>
                        <img src="@/assets/images/swiper/expanders/polypropylene_2.webp" class="product-image" loading="lazy" @click="toggleModal"/>
                    </swiper-slide>
                    <swiper-slide>
                        <img src="@/assets/images/swiper/expanders/polypropylene_3.webp" class="product-image" loading="lazy" @click="toggleModal"/>
                    </swiper-slide>
                    <swiper-slide>
                        <img src="@/assets/images/swiper/expanders/polypropylene_4.webp" class="product-image" loading="lazy" @click="toggleModal"/>
                    </swiper-slide>
                </swiper> 
            </div>
            <div class="product-thumbs">
                <swiper :slidesPerView="3" :spaceBetween="15" :modules="thumbsModules" class="thumbs" @swiper="setThumbsSwiper">
                    <swiper-slide>
                        <img src="@/assets/images/thumbs/expanders/polypropylene_1.webp" class="product-image" loading="lazy"/>
                    </swiper-slide>
                    <swiper-slide>
                        <img src="@/assets/images/thumbs/expanders/polypropylene_2.webp" class="product-image" loading="lazy"/>
                    </swiper-slide>
                    <swiper-slide>
                        <img src="@/assets/images/thumbs/expanders/polypropylene_3.webp" class="product-image" loading="lazy"/>
                    </swiper-slide>
                    <swiper-slide>
                        <img src="@/assets/images/thumbs/expanders/polypropylene_4.webp" class="product-image" loading="lazy"/>
                    </swiper-slide> 
                </swiper>
            </div>
        </div>
        <div class="product-description">
            <Name class="product-modal pc"/>
            <div class="product-modal">
                <h3 class="product-text"> {{ text[0] }} </h3>
                <Description/>
            </div>
            <div class="product-modal">
                <h3 class="product-text"> {{ text[1] }} </h3>
                <Options/>  
            </div>
            <div class="product-modal">
                <h3 class="product-text"> {{ text[2] }} </h3>
                <Usage/>
            </div>
            <Info class="product-modal"/>
        </div>
    </div>
</template>

<script>
import Name from '@/components/products/expanders/polypropylene/Name.vue'
import Description from '@/components/products/expanders/polypropylene/Description.vue'
import Usage from '@/components/products/expanders/polypropylene/Usage.vue'
import Options from '@/components/products/expanders/polypropylene/Options.vue'
import Info from '@/components/products/Info.vue'

import { Swiper, SwiperSlide } from "swiper/vue"
import { Pagination, Navigation, Thumbs, Controller, Zoom } from "swiper/modules"

import "swiper/css"
import "swiper/css/pagination"
import "swiper/css/navigation"
import "swiper/css/thumbs"
import 'swiper/css/zoom'

export default {
    components: { Name, Description, Usage, Options, Info, Swiper, SwiperSlide },
    data() {
        return {
            modal: false,
            mainModules: [ Pagination, Navigation, Thumbs, Controller ],
            modalModules: [ Pagination, Navigation, Controller, Zoom ],
            thumbsModules: [ Pagination, Navigation, Thumbs ],
            mainSwiper: null,
            modalSwiper: null,
            thumbsSwiper: null
        }
    },
    computed: {
        text() {
            if(this.$store.state.selectedLanguage === 'en') {
                return ['Description:', 'Packing method:', 'Example use:']
            }
            else {
                return ['Opis:', 'Sposoby konfekcji ekspandorów:', 'Przykładowe zastosowanie:']
            }
        }
    },
    methods: {
        setMainSwiper(swiper) {
           this.mainSwiper = swiper;
        },
        setModalSwiper(swiper) {
           this.modalSwiper = swiper;
        },
        setThumbsSwiper(swiper) {
           this.thumbsSwiper = swiper;
        },
        toggleModal() {
            this.modal = true;
        },
        closeModal() {
            this.modal = false;
        }
    }
}
</script>
