<template>
   <li v-for='(item, index) in description' :key='index'>{{ item }}</li>   
</template>

<script>
    export default {
        computed: {
            description() {
                if(this.$store.state.selectedLanguage === 'en') {
                    return ['braid - made of the highest quality polyester silk (PES)',
                            'diameter - from 3mm to 10mm',
                            'do not absorb water',
                            'very tough',
                            'resistant to high temperature',
                            'packing method: in a carton, on a spool',
                            'directly from the Polish manufacturer']
                }
                else {
                    return ['oplot - najwyższej jakości jedwab poliestrowy (PES)',
                            'średnica - od 3mm do 10mm ',
                            'nie chłoną wody',
                            'bardzo wytrzymałe',
                            'odporne na wysokie temperatury',
                            'sposób konfekcji: w kartonach, na szpulach',
                            'bezpośrednio od polskiego producenta']
                }
            }
        }
    }
</script>