<template>
    <p> {{ text }} </p>
</template>

<script>
    export default {
        computed: {
            text() {
                if(this.$store.state.selectedLanguage === 'en') {
                    return 'We make cable braids from polypropylene or polyester yarn.'
                }
                else {
                    return 'Wykonujemy oploty kabli z przędzy polipropylenowej lub poliestrowej.'
                }
            }
        }
    }
</script>