<template>
  <div class="products">
    <div class="products-first-row">
      <transition name="appear" appear>
        <router-link class="products-routes" to="/products/ropes/braided">
          <img src="@/assets/images/swiper/ropes/braided_1.webp" class="products-routes-img"/>
          <h3 class="products-routes-text"> {{ text[0] }} </h3>
        </router-link>
      </transition>
      
      <transition name="appear" appear style="transition-delay: 0.1s">
        <router-link class="products-routes" to="/products/ropes/heat-resistant">
          <img src="@/assets/images/swiper/ropes/heat-resistant_1.webp" class="products-routes-img"/>
          <h3 class="products-routes-text"> {{ text[1] }} </h3>
        </router-link>
      </transition>
    </div>
    
    <div class="products-second-row">
      <transition name="appear" appear style="transition-delay: 0.2s">
        <router-link class="products-routes" to="/products/ropes/knitted">
          <img src="@/assets/images/swiper/ropes/knitted_1.webp" class="products-routes-img"/>
          <h3 class="products-routes-text"> {{ text[2] }} </h3>
        </router-link>
      </transition>
      
      <transition name="appear" appear style="transition-delay: 0.3s">
        <router-link class="products-routes" to="/products/ropes/polyester">
          <img src="@/assets/images/swiper/ropes/polyester_1.webp" class="products-routes-img"/>
          <h3 class="products-routes-text"> {{ text[3] }} </h3>
        </router-link>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
        text() {
            if(this.$store.state.selectedLanguage === 'en') {
                return ['Polypropylene rope - braided', 'Special rope - heat-resistant', 'Polypropylene rope - knitted', 'Polyester rope']
            }
            else {
                return ['Liny polipropylenowe - plecione', 'Liny specjalne - termoodporne', 'Liny polipropylenowe - dziane', 'Liny poliestrowe']
            }
        }
    }
}
</script>
