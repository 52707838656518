<template>
   <li v-for='(item, index) in description' :key='index'>{{ item }}</li>   
</template>

<script>
    export default {
        computed: {
            description() {
                if(this.$store.state.selectedLanguage === 'en') {
                    return ['braid - made of the highest quality polyester (bistor)', 
                            'diameter - from 3mm to 6mm',
                            'directly from the Polish manufacturer']
                }
                else {
                    return ['oplot - najwyższej jakości poliester (bistor)', 
                            'średnica - od 3mm do 6mm', 
                            'bezpośrednio od polskiego producenta']
                }
            }
        }
    }
</script>