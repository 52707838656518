<template>
  <div class="about-container">
    <div class="about-first-page-container">
      <transition name="appear" appear>
        <div class="about-first-top-container">
          <div class="about-first-left-container">
            <img class="about-first-img" src="@/assets/images/swiper/strings/braided_2.webp"/>
          </div>
          <div class="about-first-right-container">
            <div class="about-logo-container">
              <div class="about-logo-inside">
                <img class="about-logo-img" src="@/assets/images/logo.png" />
              </div>
              <div class="about-slogan-inside">
                <h1>{{ tradition_first_line }}</h1>
                <h1>{{ tradition_second_line }}</h1>
            </div>
            </div>
            <h2>{{ slogan }}</h2>
          </div>
        </div>
      </transition>

      <transition name="appear" appear style="transition-delay: 0.1s">
        <div class="about-first-bottom-container">
          <h2>{{ family }}</h2>
        </div>
      </transition>
    </div>

    <div class="about-second-page-container">
      <transition name="appear" appear style="transition-delay: 0.3s">
        <div class="about-second-left-container">
          <h2>{{ specialization }}</h2>
          <img class="about-second-img" src="@/assets/images/swiper/expanders/polypropylene_2.webp"/>  
        </div>
      </transition>
      <transition name="appear" appear style="transition-delay: 0.2s">
        <div class="about-second-right-container">
          <img class="about-second-img" src="@/assets/images/swiper/ropes/braided_2.webp"/>
          <h2>{{ offer }}</h2>
        </div>
      </transition>
    </div>

    <transition name="appear" appear style="transition-delay: 0.4s">
      <div class="about-third-page-container">
        <img v-if="this.$store.state.selectedLanguage === 'en'" class="about-third-image" src="@/assets/images/quality_en.jpg"/>
        <img v-else class="about-third-image" src="@/assets/images/quality_pl.jpg"/>
        <h2>{{ character }}</h2>
      </div>
    </transition>

    <transition name="appear" appear style="transition-delay: 0.5s">
      <div class="about-fourth-page-container">
        <h2>{{ orders }}</h2>
        <div class="about-fourth-images-container">
          <img class="about-fourth-image" src="@/assets/images/swiper/ropes/braided_4.webp"/>
          <img class="about-fourth-image" src="@/assets/images/swiper/ropes/braided_7.webp"/>
        </div>
        <h2>{{ invitation }}</h2>
        <router-link to="/products">
          <img v-if="this.$store.state.selectedLanguage === 'en'" class="about-link-image" src="@/assets/images/products_link_en.jpg"/>
          <img v-else class="about-link-image" src="@/assets/images/products_link_pl.jpg"/>
        </router-link>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  computed: {
    slogan() {
      return this.$store.state.selectedLanguage === 'en' ? 'Polish manufacturer of ropes, strings, cords, and expanders of wide use.': 'Polski producent lin, sznurków i ekspandorów o szerokim zastosowaniu.';
    },
    tradition_first_line() {
      return this.$store.state.selectedLanguage === 'en' ? 'A COMPANY WITH TRADITIONS' : 'FIRMA Z TRADYCJAMI';
    },
    tradition_second_line() {
      return this.$store.state.selectedLanguage === 'en' ? '30 YEARS OF EXPERIENCE' : '30 LAT DOŚWIADCZENIA';
    },
    family() {
      return this.$store.state.selectedLanguage === 'en' ? 'Our company has existed continuously since 1989 and has a family character - for over 30 years we have been caring for its value, constantly developing it, expanding our offer, and most of all, taking care of our customers.':'Nasza firma istnieje nieprzerwanie od 1989 roku i ma charakter rodzinny - od ponad 30 lat dbamy o jej wartość stale ją rozwijając, powiększając naszą ofertę, a przede wszystkim dbając o naszych klientów.';
    },
    history() {
      return this.$store.state.selectedLanguage === 'en' ? 'We have been on the market continuously since 1989, and our offer is constantly expanding.' : 'Na rynku istniejemy nieprzerwanie od 1989 roku, a nasza oferta nieustannie się powiększa.';
    },
    specialization() {
      return this.$store.state.selectedLanguage === 'en' ? 'We specialize in the production of ropes, strings, and cords made from polypropylene, polyester, polyamide, cotton, and expanders (elastic cord) with a diameter of 1mm to 10mm, in various colours.' : 'Specjalizujemy się w produkcji lin i sznurków polipropylenowych, poliestrowych, poliamidowych, bawełnianych oraz ekspandorów (liny gumowej) o średnicy od 1mm do 10mm, w różnych wersjach kolorystycznych.';
    },
    character() {
      if(this.$store.state.selectedLanguage === 'en') {
        return 'Reliability, competence, and many years of experience have allowed us to achieve a high position in the industry. We buy both yarn and latex strands from reliable suppliers, thanks to which we can be sure that our products are of the highest quality. Timely deliveries and competitive prices on the market mean that many customers have been cooperating with us for years. For regular customers, we maintain stock levels, thanks to which we send the goods immediately after receiving the order.';
      }
      else {
        return 'Rzetelność, kompetencja i wieloletnie doświadczenie pozwoliły nam osiągnąć wysoką pozycję w branży. Zarówno przędzę jak i nici lateksowe kupujemy od sprawdzonych dostawców, dzięki temu mamy pewność, że nasze produkty są najwyższej jakości. Terminowość dostaw oraz konkurencyjne ceny na rynku sprawiają, że wielu klientów współpracuje z nami od lat. Dla stałych odbiorców utrzymujemy stany magazynowe, dzięki czemu towar wysyłamy niezwłocznie po otrzymaniu zamówienia.';
      }
    },
    offer(){
      return this.$store.state.selectedLanguage === 'en' ? 'We offer high-quality products, both one-coloured and made in several colours. Thanks to the wide range of our machine park, we can produce braided and knitted ropes, cords, and expanders.':'Oferujemy wysokiej jakości produkty, zarówno jednobarwne jak i wykonane w kilku kolorach. Dzięki szerokiej ofercie naszego parku maszynowego możemy produkować liny, sznurki i ekspandory plecione oraz dziane.';
    },
    orders() {
      return this.$store.state.selectedLanguage === 'en' ? 'We carry out orders focused on the individual needs of our clients and provide profitable solutions.' : 'Realizujemy zamówienia dostosowane do indywidualnych potrzeb klientów, zapewniając korzystne rozwiązania.';
    },
    invitation() {
      return this.$store.state.selectedLanguage === 'en' ? 'We invite you to familiarize yourself with our offer.' : 'Serdecznie zapraszamy do zapoznania się z naszą ofertą.';
    }
  }
}
</script> 
