<template>
    <li v-for='(item, index) in usage' :key='index'>{{ item }}</li>
</template>

<script>
    export default {
        computed: {
            usage() {
                if(this.$store.state.selectedLanguage === 'en') {
                    return ['the clothing industry, e.g. welt in blouses, trousers, and jackets',
                            'production of e.g. handles in gift bags, pillows, covers, rugs',
                            'arrangement, decoration of interiors and gardens: lampshades, curtains, hammocks'] 
                }
                else {
                    return ['przemysł odzieżowy np. ściągacz w bluzach, spodniach i kurtkach',
                            'produkcja np. uchwytów w torebkach prezentowych, poduszek, pokrowców, dywaników',
                            'aranżacja, dekoracja wnętrz i ogrodów: abażury, zasłony, hamaki']
                }
            }
        }
    }
</script>