<template>
   <li v-for='(item, index) in description' :key='index'>{{ item }}</li>   
</template>

<script>
    export default {
        computed: {
            description() {
                if(this.$store.state.selectedLanguage === 'en') {
                    return ['braid - 16-strand, made of the highest quality polypropylene silk', 
                            'core - additional cord made of polypropylene silk',
                            'diameter - from 2mm to 10mm',
                            'do not absorb water',
                            'very tough',
                            'UV stabilization - resistant to sunlight',
                            'resistant to various atmospheric conditions',
                            'resistant to high temperature',
                            'packing method: in a carton, on a spool',
                            'directly from the Polish manufacturer']
                }
                else {
                    return ['oplot - 16-sto splotowy, wykonany z najwyższej jakości jedwabiu polipropylenowego', 
                            'rdzeń - dodatkowy sznur, wykonany z jedwabiu polipropylenowego', 
                            'średnica - od 2mm do 10mm', 
                            'nie chłoną wody', 
                            'bardzo wytrzymałe', 
                            'stabilizacja na promieniowanie UV - odporne na działanie promieni słonecznych',
                            'odporne na różnego rodzaju warunki atmosferyczne',
                            'odporne na wysokie temperatury',
                            'sposób konfekcji: w kartonach, na szpulach',
                            'bezpośrednio od polskiego producenta']
                }
            }
        }
    }
</script>