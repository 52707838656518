<template>
   <li v-for='(item, index) in description' :key='index'>{{ item }}</li>   
</template>

<script>
    export default {
        computed: {
            description() {
                if(this.$store.state.selectedLanguage === 'en') {
                    return ['ropes made of heat-resistant para-aramid yarn - TWARON',
                            'diameter - from 1.5mm to 5mm',
                            'not combustible',
                            'resistant to very high temperature',
                            'very tough',
                            'packing method: in a carton, on a spool',
                            'directly from the Polish manufacturer']
                }
                else {
                    return ['liny wykonane z termoodpornej przędzy para-aramidowej - TWARON', 
                            'średnica - od 1,5mm do 5mm',
                            'niepalne',
                            'odporne na bardzo wysokie temperatury',
                            'bardzo wytrzymałe',
                            'sposób konfekcji: w kartonach, na szpulach',
                            'bezpośrednio od polskiego producenta']

                }
            }
        }
    }
</script>