<template>
    <li v-for='(item, index) in options' :key='index'>{{ item }}</li>
</template>

<script>
    export default {
        computed: {
            options() {
                if(this.$store.state.selectedLanguage === 'en') {
                    return ['on a spool',
                            'in a cardboard carton',
                            'in sections of any length ended with hooks on one or both sides'] 
                }
                else {
                    return ['na szpulach',
                            'w kartonach tekturowych',
                            'w odcinkach dowolnej długości zakończonych jedno lub obustronnie haczykami']
                }
            }
        }
    }
</script>