<template>
  <div class="products">
    <div class="products-first-row">
      <transition name="appear" appear>
        <router-link class="products-routes" to="/products/expanders/pcv">
          <img src="@/assets/images/swiper/expanders/pcv_1.webp" class="products-routes-img"/>
          <h3 class="products-routes-text"> {{ text[0] }} </h3>
        </router-link>
      </transition>
      
      <transition name="appear" appear style="transition-delay: 0.1s">
        <router-link class="products-routes" to="/products/expanders/polyester">
          <img src="@/assets/images/swiper/expanders/polyester_1.webp" class="products-routes-img"/>
          <h3 class="products-routes-text"> {{ text[1] }} </h3>
        </router-link>
      </transition>
     
      <transition name="appear" appear style="transition-delay: 0.2s">
        <router-link class="products-routes" to="/products/expanders/polypropylene">
          <img src="@/assets/images/swiper/expanders/polypropylene_1.webp" class="products-routes-img"/>
          <h3 class="products-routes-text"> {{ text[2] }} </h3>
        </router-link>
      </transition>
      
    </div>
  </div>
</template>

<script>
export default {
  computed: {
        text() {
            if(this.$store.state.selectedLanguage === 'en') {
                return ['Elastic cord - PCV line braided expander', 'Elastic cord - polyester braided expander', 'Elastic cord - polypropylene braided expander']
            }
            else {
                return ['Liny gumowe - ekspandor oplot żyłka PCV', 'Liny gumowe - ekspandor oplot poliestrowy', 'Liny gumowe - ekspandor oplot polipropylenowy']
            }
        }
    }
}
</script>
