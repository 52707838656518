<template>
  <div class="products">
    <div class="products-first-row">
      <transition name="appear" appear>
        <router-link class="products-routes" to="/products/strings/bistor">
          <img src="@/assets/images/swiper/strings/bistor_1.webp" class="products-routes-img"/>
          <h3 class="products-routes-text"> {{ text[0] }} </h3>
        </router-link>
      </transition>

      <transition name="appear" appear style="transition-delay: 0.1s">
        <router-link class="products-routes" to="/products/strings/braided">
          <img src="@/assets/images/swiper/strings/braided_2.webp" class="products-routes-img"/>
          <h3 class="products-routes-text"> {{ text[1] }} </h3>
        </router-link>
      </transition>
      
      <transition name="appear" appear style="transition-delay: 0.2s">
        <router-link class="products-routes" to="/products/strings/cotton">
          <img src="@/assets/images/swiper/strings/cotton_1.webp" class="products-routes-img"/>
          <h3 class="products-routes-text"> {{ text[2] }} </h3>
        </router-link>
      </transition>
    </div>

    <div class="products-second-row">
      <transition name="appear" appear style="transition-delay: 0.3s">
        <router-link class="products-routes" to="/products/strings/heat-resistant">
          <img src="@/assets/images/swiper/strings/heat-resistant_1.webp" class="products-routes-img"/>
          <h3 class="products-routes-text"> {{ text[3] }} </h3>
        </router-link>
      </transition>
    
      <transition name="appear" appear style="transition-delay: 0.4s">
        <router-link class="products-routes" to="/products/strings/knitted">
          <img src="@/assets/images/swiper/strings/knitted_1.webp" class="products-routes-img"/>
          <h3 class="products-routes-text"> {{ text[4] }} </h3>
        </router-link>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
        text() {
            if(this.$store.state.selectedLanguage === 'en') {
                return ['Polyester string - bistor', 'Polypropylene string - braided', 'Cotton string', 'Special string - heat-resistant', 'Polypropylene string - knitted']
            }
            else {
                return ['Sznurek poliestrowy - bistorowy', 'Sznurek polipropylenowy - pleciony', 'Sznurek bawełniany', 'Sznurek specjalny - termoodporny', 'Sznurek polipropylenowy - dziany']
            }
        }
    }
}
</script>
