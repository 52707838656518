<template>
   <li v-for='(item, index) in description' :key='index'>{{ item }}</li>   
</template>

<script>
    export default {
        computed: {
            description() {
                if(this.$store.state.selectedLanguage === 'en') {
                    return ['braid - made of the highest quality expander line PCV',
                            'core - elastic silicone rubber',
                            'diameter - from 3mm to 9mm',
                            'maximum stretch up to 100%',
                            'resistant to various atmospheric conditions',
                            'basic colour - black, for individual order we can produce elastic cords in various colours',
                            'directly from the Polish manufacturer']
                }
                else {
                    return ['oplot - najwyższej jakości żyłka PCV',
                            'rdzeń - guma lateksowa',
                            'średnica - od 3mm do 9mm',
                            'rozciągliwość max do 100%',
                            'odporne na różnego rodzaju warunki atmosferyczne',
                            'kolor podstawowy czarny - na indywidualne zamówienie możemy wyprodukować ekspandory w innych kolorach',
                            'bezpośrednio od polskiego producenta']
                }
            }
        }
    }
</script>