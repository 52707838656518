<template>
   <li v-for='(item, index) in description' :key='index'>{{ item }}</li>   
</template>

<script>
    export default {
        computed: {
            description() {
                if(this.$store.state.selectedLanguage === 'en') {
                    return ['braid - made of the highest quality polypropylene silk',
                            'ropes are produced with a core - stiffer or without a core - soft',
                            'diameter - from 1mm to 8mm',
                            'do not absorb water',
                            'very tough',
                            'UV stabilization - resistant to sunlight',
                            'resistant to various atmospheric conditions',
                            'resistant to high temperature',
                            'packing method: in a carton, on a spool',
                            'directly from the Polish manufacturer']
                    }
                else {
                    return ['oplot - najwyższej jakości jedwab polipropylenowy',
                            'liny produkujemy z rdzeniem - sztywniejsze lub bez rdzenia - miękkie',
                            'średnica - od 1mm do 8mm',
                            'nie chłoną wody',
                            'bardzo wytrzymałe', 
                            'stabilizacja na promieniowanie UV - odporne na działanie promieni słonecznych',
                            'odporne na różnego rodzaju warunki atmosferyczne',
                            'odporne na wysokie temperatury',
                            'sposób konfekcji: w kartonach, na szpulach', 
                            'bezpośrednio od polskiego producenta']
                }
            }
        }
    }
</script>