<template>
  <div class="products">
    <div class="products-first-row">
      <transition name="appear" appear>
        <router-link class="products-routes" to="/products/expanders">
          <img src="@/assets/images/swiper/expanders/polypropylene_1.webp" class="products-routes-img"/>
          <h3 class="products-routes-text"> {{ text[0] }} </h3>
        </router-link>
      </transition>
      
      <transition name="appear" appear style="transition-delay: 0.1s">
        <router-link class="products-routes" to="/products/strings">
          <img src="@/assets/images/swiper/strings/braided_2.webp" class="products-routes-img"/>
          <h3 class="products-routes-text"> {{ text[1] }} </h3>
        </router-link>
      </transition>

      <transition name="appear" appear style="transition-delay: 0.2s">
        <router-link class="products-routes" to="/products/ropes">
          <img src="@/assets/images/swiper/ropes/braided_1.webp" class="products-routes-img"/>
          <h3 class="products-routes-text"> {{ text[2] }} </h3>
        </router-link>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
        text() {
            if(this.$store.state.selectedLanguage === 'en') {
                return ['Expanders', 'Strings', 'Ropes']
            }
            else {
                return ['Ekspandory', 'Sznurki', 'Liny']
            }
        }
    }
}
</script>
